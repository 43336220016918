.lista {
    @apply w-full flex flex-col items-center justify-start overflow-y-scroll;
    height: 29rem;
}
.visor {
    @apply w-8/12 flex flex-col items-start justify-start;
    margin-top: 4rem;
}
.video {
    height: 29rem;
}
.inactivo{
    @apply transition duration-150 w-full bg-gray-300 text-black flex items-center justify-start p-4 mb-2 cursor-pointer;
}
.inactivo > span {
    @apply transition duration-150 font-bold select-none;
}
.activo{
    @apply transition duration-150 w-full bg-challenge text-black flex items-center justify-start p-4 mb-2 cursor-pointer;
}

.activo > span {
    @apply transition duration-150 font-bold select-none;
}
.disponible{
    @apply transition duration-150 w-full bg-challengeLight text-black flex items-center justify-start p-4 mb-2 cursor-pointer;
}

.disponible > span {
    @apply transition duration-150 font-bold select-none;
}