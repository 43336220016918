@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}
@import './app/header/Header.css';

@import './home/home.css';
@import './app/clases/clases.css';

.contenedor {
    position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 25px;
	width: 65vw;
}
.contenedor > iframe {
    position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 100%;
}
select{
	-webkit-appearance: none
}
