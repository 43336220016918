.hero{
    @apply w-full;
    height: calc(100vh - 5rem);
}
.boton-empezar{
    @apply transition duration-300 p-2 font-black text-black rounded-xl flex items-center justify-center text-lg md:text-2xl;
    box-shadow: 10px 10px 1px rgba(0,0,0,.4);
}
.mover{
    @apply absolute bg-white hover:bg-acent cursor-pointer;
    left: 17rem;
    bottom: 6rem;
}
.resaltado{
    @apply font-bold bg-white transform skew-x-6 w-12 h-4;
}
.sombradura{
    box-shadow: 10px 10px 1px rgba(0,0,0,.4);
}
.boton-invisible{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    height: 30%;
    cursor: pointer;
}
.boton-invisible-2{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 16%;
    cursor: pointer;
}